import { useContext } from 'react';
import Nav from './Nav';
import { AppContext } from '../../App';
import './PageWithNav.css';

interface Props {
  children: React.ReactNode;
}

const PageWithNav: React.FC<Props> = ({ children }) => {
  const ctx = useContext(AppContext);
  return (
    <div className="page-with-nav">
      <Nav hideLinks={ctx?.user?.company?.subscriptionStatus !== 'ACTIVE'} />
      <div className="page-with-nav__content">
        {children}
      </div>
    </div>    
  )
}

export default PageWithNav