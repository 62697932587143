import initials from 'initials';
import { User } from '../../api/auth';
import './UserChip.css';
import { TrashIcon } from '@heroicons/react/24/outline';

interface Props {
  user: User;
  small?: boolean;
  onDelete?: (user: User) => void | Promise<void>;
  compact?: boolean;
}

const UserChip: React.FC<Props> = ({ user, onDelete, small, compact }) => {
  let initialsToDisplay = initials(user.email);
  user.avatarColour;
  if (user.name.split(' ').length > 1) {
    initialsToDisplay = initials(user.name);
  }

  return (
    <div className={`user-chip ${small ? 'user-chip--small' : ''} ${compact ? 'user-chip--compact' : ''}`}>
      <div className="user-chip__info">
        <span
          className="user-chip__initials"
          style={{
            backgroundColor: `var(--${user.avatarColour}-light)`,
            color: `var(--${user.avatarColour})`,
          }}
        >
          {initialsToDisplay.toUpperCase()}
        </span>
        {!compact && (
          <div>
            <p className="user-chip__name">{user.name}</p>
            <p className="user-chip__email" title={user.email}>
              {user.email}
            </p>
          </div>
        )}
      </div>

      {!!onDelete && (
        <button className="icon-button user-chip__delete-button" onClick={() => onDelete(user)}>
          <TrashIcon />
        </button>
      )}
    </div>
  );
};

export default UserChip;
