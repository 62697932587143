import { Fragment, useState } from 'react';
import { Case, CaseUser } from '../../../api/case';
import { title } from 'case';
import CaseUserInput from './CaseUserInput';
import UserChip from '../../../components/user-chip/UserChip';
import { User } from '../../../api/auth';
import * as api from '../../../api';
import './CaseUsers.css';
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import InfoBanner from '../../../components/info-banner/InfoBanner';

interface Props {
  caseId: string;
  ownerGroups: string[];
  caseUsersInProcess: CaseUser[];
  canEdit?: boolean;
  updateCase: (updates: Partial<Case>) => void;
}

const CaseUsers: React.FC<Props> = ({
  caseId,
  ownerGroups,
  caseUsersInProcess,
  canEdit,
  updateCase,
}) => {
  const [ownerGroupOpen, setOwnerGroupOpen] = useState<string>();
  const handleAddCaseUser = (caseUser: CaseUser) => {
    let isNewCaseUser = true;
    const newCaseUsers = caseUsersInProcess.map((existingCaseUser) => {
      if (existingCaseUser.user.id === caseUser.user.id) {
        isNewCaseUser = false;
        return caseUser;
      }
      return existingCaseUser;
    });

    if (isNewCaseUser) {
      newCaseUsers.push(caseUser);
    }

    updateCase({ caseUsers: newCaseUsers });
  };

  const handleDeleteUser = async (caseUser: CaseUser, role: string) => {
    const res = await api.cases.removeCaseUser(caseId, caseUser.user.id, role);
    if (res.data && res.data.caseUserRemoved) {
      const newCaseUsers = caseUsersInProcess.filter(
        (existingCaseUser) => existingCaseUser.user.id !== caseUser.user.id,
      );
      updateCase({ caseUsers: newCaseUsers });
    } else if (res.data) {
      const newCaseUsers = caseUsersInProcess.map((existingCaseUser) => {
        if (existingCaseUser.id === caseUser.id) {
          return res.data!.caseUser;
        }

        return existingCaseUser;
      });

      updateCase({ caseUsers: newCaseUsers });
    }
  };
  return (
    <Fragment>
      <h3 className="case-users__title">Case Users</h3>
      {ownerGroups.map((ownerGroup) => {
        const caseUsersInGroup = caseUsersInProcess.filter((caseUser) =>
          caseUser.roles.includes(ownerGroup),
        );
        return (
          <div key={ownerGroup} className="case-users__group-container">
            <div className="case-users__group-header">
              <h4 className="case-users__group-title">
                {ownerGroup.length > 2 ? title(ownerGroup) : ownerGroup}
              </h4>
              <button className="icon-button" style={{ color: 'var(--blue)' }} onClick={() => setOwnerGroupOpen(ownerGroup)}>
                <PlusCircleIcon />
              </button>
            </div>

            {caseUsersInGroup.map((caseUser) => {
              return (
                <div
                  className="case-users__user-chip-container"
                  key={`${caseUser.id}_${ownerGroup}`}
                >
                  <UserChip
                    user={caseUser.user as User}
                    onDelete={() => handleDeleteUser(caseUser, ownerGroup)}
                  />
                </div>
              );
            })}
            {!caseUsersInGroup.length && (
              <InfoBanner variant='info' className='case-users__group-info-banner'>
                <p>No users in this group</p>
              </InfoBanner>
            )}
            {!!canEdit && (
              <CaseUserInput
                isOpen={ownerGroupOpen === ownerGroup}
                setIsOpen={() => setOwnerGroupOpen(ownerGroup)}
                onAddCaseUser={handleAddCaseUser}
                existingGroupCaseUsers={caseUsersInGroup}
                ownerGroup={ownerGroup}
                caseId={caseId}
                handleClose={() => setOwnerGroupOpen(undefined)}
              />
            )}
          </div>
        );
      })}
    </Fragment>
  );
};

export default CaseUsers;
