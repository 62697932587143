import * as api from '../../api';
import { NewProcessFields } from '../../api/process/admin';
import { useNavigate } from 'react-router-dom';
import ProcessDetailsForm from '../../modules/admin/process-builder/process-details-form/ProcessDetailsForm';

interface Props {}

const NewProcessPage: React.FC<Props> = () => {
  const navigate = useNavigate();

  const handleFormSubmit = async (formData: NewProcessFields) => {
    const res = await api.processes.admin.create(formData);
    if (res.data) {
      navigate(`/admin/process/${res.data.id} `);
    }
  }

  return (
    <main className="content" style={{ display: 'flex', justifyContent: 'center', }}>
      <div className="box" style={{ width: '50vw', minWidth: '300px'}}>
        <h1>New Process</h1>
        <ProcessDetailsForm onSubmit={handleFormSubmit} />
      </div>
    </main>
  );
};

export default NewProcessPage;
