import { useState } from 'react';
import './GuidanceNote.css';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import Markdown from 'react-markdown';

const SHOW_LESS_LENGTH = 300;

interface Props {
  note: string;
}

// Regular expressions to identify links and images
const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
const imageRegex = /!\[([^\]]*)\]\((https?:\/\/[^\s]+)\)/g;

const GuidanceNote: React.FC<Props> = ({ note }) => {
  const [showMore, setShowMore] = useState(false);

  const needsShowMore = false;
  return (
    <div className="guidance-note__container">
      <div>
        <h4 className="guidance-note__title">
          Guidance Note <LightBulbIcon />{' '}
        </h4>
        <div className="guidance-note__text">
          <Markdown>{note}</Markdown>
          {needsShowMore && (
            <button className="link-button" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'see less' : 'see more'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuidanceNote;
