import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CaseItem } from '../../../api/case';
import { Process } from '../../../api/process';
import './CaseItemList.css';
interface Props {
  process: Process;
  items: CaseItem[];
  onSelectItem: (item: CaseItem) => void;
  selectedItem?: CaseItem;
  onAddNewItem: () => void;
  onDeleteItem: (item: CaseItem) => void;
}

const CaseItemList: React.FC<Props> = ({
  items,
  onSelectItem,
  process,
  selectedItem,
  onAddNewItem,
  onDeleteItem,
}) => {

  return (
    <div className="case-items-list">
      {items.map((item) => {
        const displayField = item.data[process.itemDisplayField];
        return (
          <div
            key={item.id}
            onClick={() => onSelectItem(item)}
            className={`case-items-list__item ${
              selectedItem?.id === item.id ? 'case-items-list__item--selected' : ''
            }`}
          >
            {displayField}
            <button
              className="icon-button"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteItem(item);
              }}
            >
              <TrashIcon />
            </button>
          </div>
        );
      })}
      {!!selectedItem && (
        <button className="case-items-list__add-new" onClick={onAddNewItem}>
          <PlusIcon />
          Add new {process.itemName}
        </button>
      )}
      {!items.length && (
        <div className="case-items-list__empty">
          <p>No items added yet</p>
        </div>
      )}
    </div>
  );
};

export default CaseItemList;
