import { InformationCircleIcon } from '@heroicons/react/24/outline'
import './InfoBanner.css'

interface Props {
  icon?: JSX.Element
  message?: string | JSX.Element
  children?: JSX.Element
  variant?: 'warning' | 'success' | 'info'
  className?: string
}

const defaultIcon = <InformationCircleIcon />

const InfoBanner: React.FC<Props> = ({ icon = defaultIcon, message, variant, children, className}) => {

  return (
    <p className={`info-banner ${variant ? `info-banner--${variant}` : ''} ${className}`}>{icon} {message}{children}</p>
  )
}

export default InfoBanner