import { Fragment, useContext } from 'react';
import { Case } from '../../../api/case';
import GuidanceNote from '../../../components/guidance-note/GuidanceNote';
import { title } from 'case';
import './CaseStep.css';
import CaseStepCompletion from './CaseStepCompletion';
import { canUserCompleteCurrentStep, getNextSteps, ownerGroupToTitleCase } from '../utils';
import { AppContext } from '../../../App';
import NextSteps from './NextSteps';
import DueDate from '../../../components/due-date/DueDate';
import InfoBanner from '../../../components/info-banner/InfoBanner';

interface Props {
  _case: Case;
  caseStepId: string;
  updateCase: (updates: Partial<Case>) => void;
}

const CaseStep: React.FC<Props> = ({ _case, caseStepId, updateCase }) => {
  const ctx = useContext(AppContext);
  const activeStep = _case.caseSteps.find((step) => step.id === caseStepId);
  const processStep = _case.process.steps.find((step) => step.id === activeStep?.processStepId);

  const canCompleteCurrentStep = activeStep
    ? canUserCompleteCurrentStep(_case, activeStep?.processStepId, ctx.user.id)
    : false;

  const nextSteps = activeStep ? getNextSteps(_case, activeStep.processStepId) : [];

  const isActiveAndUnableToComplete =
    activeStep && !canCompleteCurrentStep && !activeStep.completedAt;
  return (
    <Fragment>
      {!activeStep && <p>No active step</p>}
      {!!(activeStep && processStep) && (
        <div className="case-step__container">
          <div className="case-step__main-container">
            <div className="case-step__meta">
              <p className="case-step__type">{title(processStep.type)}:</p>
              {!activeStep.completedAt && !!activeStep.dueDate && (
                <DueDate
                  date={activeStep.dueDate}
                  style={{ justifyContent: 'flex-end' }}
                  title="This task must be completed by the due date"
                />
              )}
            </div>

            <h3 className="case-step__name">{processStep.name}</h3>
            {isActiveAndUnableToComplete && (
              <InfoBanner className="case-step__owners">
                <>
                This can only be completed by a{' '}
                {processStep.ownerGroups
                  .map((ownerGroup) => ownerGroupToTitleCase(ownerGroup))
                  .join(', ')}{' '}
                user
                </>
              </InfoBanner>
            )}
            {!!processStep.description && (
              <p className="case-step__description">{processStep.description}</p>
            )}
            <CaseStepCompletion
              _case={_case}
              updateCase={updateCase}
              currentCaseStep={activeStep}
              currentProcessStep={processStep}
              canCompleteCurrentStep={canCompleteCurrentStep && !activeStep.completedAt}
              nextProcessSteps={nextSteps}
            />
          </div>
          <div className="case-step__next-step-container">
            {!!processStep.guidanceNotes && <GuidanceNote note={processStep.guidanceNotes} />}

            {!processStep.guidanceNotes && (
              <NextSteps steps={nextSteps} currentStepId={activeStep.processStepId} />
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CaseStep;
