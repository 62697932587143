import { Case, CaseStep } from '../../../api/case';
import { Automation } from '../../../api/process';
import * as api from '../../../api';
import ItemFields from '../../../components/item-field-form/ItemFields';
import { Fragment, useState } from 'react';
import { BoltIcon } from '@heroicons/react/24/outline';
import './LetterAutomation.css';
import { trackEvent } from '../../..';

interface Props {
  updateCase: (updates: Partial<Case>) => void;
  supportingData: CaseStep['supportingData'];
  setSupportingData: (supportingData: CaseStep['supportingData']) => void;
  caseId: string;
  stepHasSupportingDataCompleted: boolean;
  currentCaseStepId: string;
  letterAutomation: Automation['letter'];
  readOnly: boolean;
}

const LetterAutomation: React.FC<Props> = ({
  updateCase,
  supportingData,
  setSupportingData,
  stepHasSupportingDataCompleted,
  currentCaseStepId,
  caseId,
  letterAutomation,
  readOnly,
}) => {
  const [uploadOwn, setUploadOwn] = useState(false);
  if (!letterAutomation) {
    return <Fragment></Fragment>;
  }

  const handleGenerateLetterClick = async() => {
    const res = await api.cases.generateLetter(caseId, currentCaseStepId);
    if (res.data) {
      trackEvent('documentGenerated')
      updateCase(res.data)
    }
  }

  const hasLetter = !!supportingData[letterAutomation.name];
  let disableLetterGeneration = hasLetter;
  if (!disableLetterGeneration && letterAutomation.requiresCurrentStepData) {
    disableLetterGeneration = !stepHasSupportingDataCompleted;
  }
  return (
    <div className='letter-automation'>
      <BoltIcon style={{ float: 'right', color: 'var(--yellow)'}} />
      <Fragment>
        <ItemFields
          fields={[{ name: letterAutomation.name, type: 'file', requiredOnCreate: true }]}
          values={supportingData}
          setValues={setSupportingData}
          isEditing={!readOnly && (hasLetter || uploadOwn)}
          entityId={caseId}
        />

        {!readOnly && (
          <Fragment>
            <div className="letter-automation__actions">
              <button
                className='button button--secondary button--small'
                type="button"
                disabled={disableLetterGeneration}
                onClick={handleGenerateLetterClick}
              >
                <BoltIcon />
                Generate document
              </button>
              <button className='link-button' type="button" disabled={hasLetter} onClick={() => setUploadOwn(!uploadOwn)}>
                Upload your own
              </button>{' '}
            </div>
            {letterAutomation.requiresCurrentStepData && !stepHasSupportingDataCompleted && (
              <small>Complete (and save) the fields above to enable document generation</small>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default LetterAutomation;
