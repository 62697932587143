import { useEffect, useState } from 'react';
import { UploadedFile } from '../../../api/file';
import * as api from '../../../api';
import FileUpload from '../../../components/file-upload/FileUpload';
import FileList from '../../../components/file-list/FileList';
import './CaseDocuments.css';
import { trackEvent } from '../../..';

interface Props {
  caseId: string;
  refreshAuditLogs: () => void;
}

const CaseDocuments: React.FC<Props> = ({ caseId, refreshAuditLogs }) => {
  const [documents, setDocuments] = useState<UploadedFile[]>([]);

  const getDocuments = async () => {
    const res = await api.files.list(caseId, 'CASE');
    if (res.data) {
      setDocuments(res.data);
    }
  };

  useEffect(() => {
    getDocuments();
  }, [caseId]);

  const handleFileUploaded = (uploadedFile: UploadedFile) => {
    setDocuments([...documents, uploadedFile]);
    trackEvent('documentUploaded');
    refreshAuditLogs();
  };

  return (
    <div className="case-documents">
      <div className="case-documents__list-container">
        <h3 className="case-documents__list-title">Documents</h3>
        <FileList files={documents} refreshAuditLogs={refreshAuditLogs} />
      </div>

      <div className="case-documents__upload-container">
        <h4 className="case-documents__upload-title">Upload a new document</h4>
        <FileUpload entityId={caseId} entityType="CASE" onUploadFn={handleFileUploaded} />
      </div>
    </div>
  );
};

export default CaseDocuments;
