import { Link, useLocation } from 'react-router-dom';
import { jwtLocalStorageKey } from '../../api/auth';
import {
  ArrowLeftOnRectangleIcon,
  ChartBarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardIcon,
  CogIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { getBrand } from '../../utils/brand';
import { trackEvent } from '../..';
import './Nav.css';
import UserChip from '../user-chip/UserChip';

interface Props {
  hideLinks?: boolean;
}

const Nav: React.FC<Props> = ({ hideLinks }) => {
  const ctx = useContext(AppContext);
  const path = useLocation().pathname;
  const [expanded, setExpanded] = useState(true);

  const logout = () => {
    window.localStorage.removeItem(jwtLocalStorageKey);
    window.location.reload();
  };

  const logoUrl = getBrand() === 'operonix' ? '/operonix-logo.jpg' : '/logo.png';

  const handleSearchClick = () => {
    ctx.setShowSearch(true);
    trackEvent('searchOpened');
  };

  useEffect(() => {
    if (path.includes('analytics')) {
      setExpanded(false);
    }
  }, [path]);

  // If viewport width drops below 800px, force the nav to be collapsed
  // If viewport width goes above 1400px, force the nav to be expanded
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setExpanded(false);
      } else if (window.innerWidth > 1400 && !path.includes('analytics')) {
        setExpanded(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className={`nav ${expanded ? 'nav--expanded' : ''}`}>
      <div className="nav__company">
        <img className="nav__logo" src={logoUrl} />
        {expanded && <span className="nav__company-name">{ctx.user.company.name}</span>}
      </div>
      <button className="nav__toggle-button" onClick={() => setExpanded(!expanded)}>
        {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </button>
      <div className="nav__links">
        <div className="nav__links-top">
          {!hideLinks && (
            <Fragment>
              <button className="nav__link-button" onClick={handleSearchClick}>
                <div className="nav__button" title="Search" >
                  <MagnifyingGlassIcon />
                </div>
                {expanded && <span className="nav__link-button-text">Search</span>}
              </button>
              <Link className="nav__link-button" to="/" title="Home">
                <div className="nav__button" >
                  <HomeIcon />
                </div>
                {expanded && <span className="nav__link-button-text">Home</span>}
              </Link>
              {ctx.user.roles.includes('supervisor') && (
                <Link className="nav__link-button" to="/supervisor" title="Supervisor">
                  <div className="nav__button">
                    <ClipboardIcon />
                  </div>
                  {expanded && <span className="nav__link-button-text">Supervisor</span>}
                </Link>
              )}
              {ctx.user.roles.includes('supervisor') && (
                <Link className="nav__link-button" to="/analytics" title="Analytics">
                  <div className="nav__button">
                    <ChartBarIcon />
                  </div>
                  {expanded && <span className="nav__link-button-text">Analytics</span>}
                </Link>
              )}
              {ctx.user.roles.includes('admin') && (
                <Link className="nav__link-button" to="/admin" title="Admin">
                  <div className="nav__button">
                    <CogIcon />
                  </div>
                  {expanded && <span className="nav__link-button-text">Admin</span>}
                </Link>
              )}

              <Link className="nav__link-button" title="Profile" to="/profile">
                <div className="nav__button">
                  <UserIcon />
                </div>
                {expanded && <span className="nav__link-button-text">Profile</span>}
              </Link>
            </Fragment>
          )}
        </div>
        <div className="nav__links-bottom">
          <a className="nav__link-button" title="Help"
              target="_blank"
              href="https://operonix.com/support">
            <div
              className="nav__button"
              
            >
              <QuestionMarkCircleIcon />
            </div>
            {expanded && <span className="nav__link-button-text">Help</span>}
          </a>
          <button className="nav__link-button" title="Logout" onClick={logout}>
            <div className="nav__button">
              <ArrowLeftOnRectangleIcon />
            </div>
            {expanded && <span className="nav__link-button-text">Logout</span>}
          </button>
          <div className="nav__user">
            {!!ctx.user && (
              <UserChip user={ctx.user} compact={!expanded} />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
