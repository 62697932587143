import { Fragment, useContext, useEffect, useState } from 'react';
import { Case, CaseItem } from '../../../api/case';
import CaseItemInput from '../case-items/CaseItemInput';
import Table, { TableHeader } from '../../../components/table/Table';
import { lower, title } from 'case';
import * as api from '../../../api';
import '../case-items/CaseItems.css';
import { Process } from '../../../api/process';
import InfoBanner from '../../../components/info-banner/InfoBanner';
import { ExclamationTriangleIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { AppContext } from '../../../App';
import { Link } from 'react-router-dom';
import Modal from '../../../components/modal/Modal';
import CaseItemCsvUpload from '../case-items/CaseItemCsvUpload';
import CaseItemList from './CaseItemList';
import ItemFieldForm from '../../../components/item-field-form/ItemFieldForm';
import './CaseItemsV2.css';

interface Props {
  caseId: string;
  caseItems: CaseItem[];
  process: Process;
  canEdit?: boolean;
  canAdd: boolean;
  updateCase: (updates: Partial<Case>) => void;
}

const CaseItems: React.FC<Props> = ({
  caseId,
  caseItems,
  process,
  canEdit,
  canAdd,
  updateCase,
}) => {
  const ctx = useContext(AppContext);
  const [selectedItem, setSelectedItem] = useState<any>();

  useEffect(() => {
    if (caseItems.length) {
      setSelectedItem(caseItems[0]);
    }
  }, [caseItems]);

  const handleNewItem = async (newItem: any) => {
    const res = await api.cases.createItem(caseId, newItem);
    if (res.data) {
      updateCase({ items: [...caseItems, res.data] });
      setSelectedItem(res.data);
    }
  };

  const handleDeleteItem = async (itemToDelete: CaseItem) => {
    const res = await api.cases.deleteItem(caseId, itemToDelete.id);
    if (!res.error) {
      const updatedItems = caseItems.filter((item) => item.id !== itemToDelete.id);
      updateCase({ items: updatedItems });
    }
  };

  const handleBulkUpdate = async (items: CaseItem[]) => {
    const res = await api.cases.upsertItems(caseId, items);
    if (res.data) {
      updateCase(res.data);
    }
  };

  const handleUpdate = async (updatedData: any) => {
    if (!selectedItem) return;

    const res = await api.cases.updateItem(caseId, selectedItem.id, updatedData);
    if (!res.error) {
      const updatedCaseItems = caseItems.map((caseItem) => {
        if (caseItem.id !== selectedItem.id) {
          return caseItem;
        }

        return {
          ...caseItem,
          data: updatedData,
        };
      });
      updateCase({ items: updatedCaseItems });
      setSelectedItem(undefined);
    }
  };

  const reachedMaxItems = !!process.maxItems && caseItems.length >= process.maxItems;
  const itemNamePrefix = process.maxItems === 1 ? '' : process.itemName.endsWith('s') ? '' : 's';
  const itemName = title(`${process.itemName}${itemNamePrefix}`);
  return (
    <div>
      {!process.itemFields.length && canAdd && (
        <InfoBanner
          variant="warning"
          icon={<ExclamationTriangleIcon />}
          message={
            ctx.user.roles.includes('admin') ? (
              <span>
                Case items haven't been configured yet, you can do this in{' '}
                <Link to={`/admin/process/${process.id}`}>process admin</Link>.
              </span>
            ) : (
              "Case items haven't been configured yet, contact your administrator to fix this"
            )
          }
        />
      )}
      {!!process.itemFields.length && (
        <div className="case-items">
          {process.maxItems !== 1 && (
            <div className="case-items__list-container">
              <div className="case-items__header">
                <h3 className="case-items__name">{itemName || 'Case Items'}</h3>
                {reachedMaxItems && canAdd && (
                  <InfoBanner>
                    <>
                      You can only add {process.maxItems} {itemName}
                    </>
                  </InfoBanner>
                )}
                <div>
                  {canAdd && !reachedMaxItems && !!process.itemFields.length && (
                    <CaseItemCsvUpload process={process} updateCase={updateCase} caseId={caseId} />
                  )}
                  {/* <button style={{marginLeft: '16px'}} onClick={() => setMode(mode === 'SPREADSHEET' ? 'STANDARD' : 'SPREADSHEET')} className='link-button'>Switch to {mode === 'SPREADSHEET' ? 'standard' : 'spreadsheet'} input</button> */}
                </div>
              </div>
              <CaseItemList
                items={caseItems}
                onSelectItem={setSelectedItem}
                process={process}
                selectedItem={selectedItem}
                onAddNewItem={() => setSelectedItem(undefined)}
                onDeleteItem={handleDeleteItem}
              />
            </div>
          )}
          <div className="case-items__form-container">
            {!selectedItem && (
              <ItemFieldForm
                fields={process.itemFields}
                onSubmit={handleNewItem}
                formTitle={`Add ${itemName}`}
                initialValues={{}}
                caseId={caseId}
                editOnRender={true}
              />
            )}
            {!!selectedItem && (
              <ItemFieldForm
                fields={process.itemFields}
                onSubmit={handleUpdate}
                formTitle={`Edit ${itemName}`}
                initialValues={selectedItem.data}
                caseId={caseId}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseItems;
