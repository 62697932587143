import { endOfMonth, startOfMonth } from 'date-fns';
import { format } from 'date-fns-tz';
import { AnalyticsFilters } from '../../api/analytics';
import { Case } from '../../api/case';
import Input from '../../components/input/Input';
import FilterCheckboxList from './FilterCheckboxList';
import { TrashIcon } from '@heroicons/react/24/outline';
import './AnalyticsFilters.css';

interface Props {
  filters?: AnalyticsFilters;
  availableFilters?: AnalyticsFilters;
  setFilters: (filters: AnalyticsFilters) => void;
}

// Get the current date in UTC
const currentDate = new Date();

// Calculate the start and end of the month in UTC
const startOfMonthUTC = startOfMonth(currentDate);
const endOfMonthUTC = endOfMonth(currentDate);

// Convert UTC dates to the target timezone (in this case, keeping it UTC)
const startOfMonthUTCString = format(startOfMonthUTC, 'yyyy-MM-dd', { timeZone: 'UTC' });
const endOfMonthUTCString = format(endOfMonthUTC, 'yyyy-MM-dd', { timeZone: 'UTC' });

export const emptyFilters = {
  // Default to start of the current month in UTC
  startDate: startOfMonthUTCString,
  // Default to end of the current month in UTC
  endDate: endOfMonthUTCString,
  processIds: [],
  caseStatuses: [],
  processCategories: [],
};

const AnalyticsFiltersComponent: React.FC<Props> = ({ filters, setFilters, availableFilters }) => {
  return (
    <div className="analytics-filters">
      <div className="analytics-filters__header">
        <h1 className="analytics-filters__title">Analytics</h1>
        <button
          className="link-button analytics-filters__clear-button"
          style={{ marginLeft: 'auto' }}
          onClick={() => setFilters(emptyFilters)}
        >
          Clear
          <TrashIcon />
        </button>
      </div>

      <div className="analytics-filters__date-inputs">
        <Input
          id="start-date"
          labelText="Date from"
          value={filters?.startDate || ''}
          onChange={(startDate) => setFilters({ ...filters, startDate })}
          type="date"
          small={true}
        />
        <Input
          id="end-date"
          labelText="Date to"
          value={filters?.endDate || ''}
          onChange={(endDate) => setFilters({ ...filters, endDate })}
          type="date"
          small={true}
        />
      </div>

      <Input
        id="exclude-dates"
        labelText="Cases without due dates"
        type="checkbox"
        value={(!filters?.startDate)?.toString() || ''}
        onChange={(val) => {
          console.log(val);
          if (val === 'true') {
            setFilters({
              ...filters,
              startDate: undefined,
              endDate: undefined,
            });
          } else {
            setFilters({
              ...filters,
              startDate: startOfMonthUTCString,
              endDate: endOfMonthUTCString,
            });
          }
        }}
      />
      <FilterCheckboxList
        availableFilters={availableFilters?.processIds}
        selectedFilters={filters?.processIds}
        setFilters={(processIds) => setFilters({ ...filters, processIds })}
        labelText="Processes"
      />
      <FilterCheckboxList
        availableFilters={availableFilters?.processCategories}
        selectedFilters={filters?.processCategories}
        setFilters={(processCategories) => setFilters({ ...filters, processCategories })}
        labelText="Process Categories"
      />

      <FilterCheckboxList
        availableFilters={availableFilters?.caseStatuses}
        selectedFilters={filters?.caseStatuses}
        setFilters={(caseStatuses) =>
          setFilters({ ...filters, caseStatuses: caseStatuses as Case['status'][] })
        }
        labelText="Case Statuses"
      />
    </div>
  );
};

export default AnalyticsFiltersComponent;
