import * as Case from 'case';
import { Fragment, useContext, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import Input from '../input/Input';
import './Table.css';
import { formatDate } from '../../utils/date-utils';
import { AppContext } from '../../App';
import {  Table } from '@radix-ui/themes';

export type TableHeader<T = any> = keyof T | HeaderComponent<T>

export type HeaderComponent<T> = (record?: T) => { element: JSX.Element | string; header: string };
interface Props {
  headers?: TableHeader[];
  data: Record<string, any>[];
  search?: boolean;
  onDelete?: (record: any) => void | Promise<void>;
  maxHeight?: string;
}

function isDateOrConvertible(value: any): boolean {
  // Regex patterns for YYYY-MM-DD and DD/MM/YYYY formats
  const ymdPattern = /^\d{4}-\d{2}-\d{2}/; // YYYY-MM-DD
  const dmyPattern = /^\d{2}\/\d{2}\/\d{4}/; // DD/MM/YYYY

  if (value instanceof Date) {
      return !isNaN(value.getTime()); // Check if the Date object is valid
  } else if (typeof value === 'string') {
      // Check if the string matches either of the date patterns
      if (ymdPattern.test(value) || dmyPattern.test(value)) {
          // Attempt to parse the string to a date based on format
          let parsedDate;
          if (ymdPattern.test(value)) {
              parsedDate = new Date(value); // Parse YYYY-MM-DD
          } else if (dmyPattern.test(value)) {
              // Parse DD/MM/YYYY, swap day and month before parsing
              const [day, month, year] = value.split('/');
              parsedDate = new Date(`${year}-${month}-${day}`);
          }

          if (!parsedDate) return false;

          return !isNaN(parsedDate.getTime()); // Check if the parsed date is valid
      }
  }

  return false; // If it's not a Date object, a valid date string, or doesn't match the patterns, return false
}

const TableComponent: React.FC<Props> = ({ headers, data, search, onDelete, maxHeight }) => {
  const ctx = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState('');

  const headersToUse = headers || Object.keys(data[0] || {});

  let dataToDisplay = [...data];
  if (searchTerm) {
    dataToDisplay = dataToDisplay.filter((dataPoint) => {
      const keys = Object.keys(dataPoint);
      let searchMatch = false;
      keys.forEach((key) => {
        if (String(dataPoint[key]).toLowerCase().includes(searchTerm.toLowerCase())) {
          searchMatch = true;
        }
      });

      return searchMatch;
    });
  }
  return (
    <Fragment>
      {!!search && (
        <Input id="table-search" labelText="Search" type="search" value={searchTerm} onChange={setSearchTerm} />
      )}
      <Table.Root style={{ maxHeight, width: '100%' }}>
        <Table.Header>
          <Table.Row>
            {!!onDelete && !!data.length && <Table.ColumnHeaderCell>Delete</Table.ColumnHeaderCell>}
            {headersToUse.map((header) => {
              return (
                <Table.ColumnHeaderCell key={typeof header === 'string' ? header : header?.toString()}>
                  {typeof header === 'string' ? Case.title(header) : (header as any)().header}
                </Table.ColumnHeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataToDisplay.map((row, rowIndex) => {
            return (
              <Table.Row key={`table_row_${rowIndex}`}>
                {!!onDelete && !!data.length && (
                  <Table.Cell>
                    <button className="icon-button" type="button" onClick={() => onDelete(row)}>
                      <TrashIcon />
                    </button>
                  </Table.Cell>
                )}
                {headersToUse.map((header, headerIndex) => {
                  const key = `table_cell_${rowIndex}_${headerIndex}`;
                  if (typeof header !== 'string') {
                    return <Table.Cell key={key}>{(header as any)(row).element}</Table.Cell>;
                  }

                  let value = row[header];
                  if (header === 'id') {
                    value = `${value.slice(0, 6)}...`;
                  }

                  if (typeof value === 'string' && value.length > 50) {
                    value = `${value.slice(0, 50)}...`;
                  }

                  if (typeof value === 'boolean') {
                    value = value ? 'Yes' : 'No'
                  }

                  if (!!value && typeof value === 'object' && value.length) {
                    value = JSON.stringify(value)
                  }

                  if (!value) {
                    value = '';
                  }

                  if (isDateOrConvertible(value)) {
                    const dateValue = new Date(value);
                    value = formatDate(dateValue, ctx.user.company.locale, ctx.user.company.timezone)
                  }

                  if (Array.isArray(value)) {
                    value = value.join(', ')
                  }

                  if (typeof value !== 'string') {
                    value = JSON.stringify(value);
                  }
                  return <Table.Cell key={key}>{value}</Table.Cell>;
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
    </Fragment>
  );
};

export default TableComponent;
