import { HTMLInputTypeAttribute, useEffect } from 'react'
import styles from './Input.module.css'
import DatePicker from 'react-datepicker'
import CurrencyInput, { formatValue, cleanValue } from 'react-currency-input-field'
import { Switch } from '@radix-ui/themes'

export interface InputProps {
  labelText: string
  helpText?: string
  id: string
  type?: HTMLInputTypeAttribute | 'textarea' | 'date' | 'currency'
  value: string
  onChange: (value: string) => void
  readOnly?: boolean
  small?: boolean
  containerClassName?: string
  inputClassName?: string
  inputStyle?: React.CSSProperties
  disabled?: boolean
  placeholder?: string
  invalid?: boolean
  maxLength?: number
  minLength?: number
}

const Input: React.FC<InputProps> = ({
  labelText,
  id,
  type,
  value,
  onChange,
  helpText,
  readOnly,
  small,
  inputClassName,
  inputStyle,
  containerClassName,
  disabled,
  placeholder,
  invalid,
  maxLength,
  minLength,
}) => {
  let readOnlyValue = value
  if (type === 'date' && value) {
    readOnlyValue = new Date(value).toLocaleDateString()
  }

  useEffect(() => {
    if (type === 'checkbox' && !value) {
      onChange('false')
    }
  }, [type, value, onChange])

  if (type === 'checkbox' && value === 'true') {
    readOnlyValue = 'Yes'
  }

  if (type === 'checkbox' && value === 'false') {
    readOnlyValue = 'No'
  } 

  const nonStandardInputTypes = ['textarea', 'date', 'file', 'currency', 'checkbox']

  let inputMode: React.HTMLAttributes<HTMLInputElement>['inputMode'] = 'text'
  if (type === 'number') inputMode = 'numeric'
  if (type === 'search') inputMode = 'search'
  if (type === 'email') inputMode = 'email'

  let helpTextToRender: string | undefined = helpText
  if (minLength && maxLength && minLength === maxLength) {
    helpTextToRender = `${helpTextToRender}${helpTextToRender ? '.' : ''} Must be ${maxLength} ${
      type === 'number' ? 'numbers' : 'characters'
    }`
  } else if (minLength && maxLength) {
    helpTextToRender = `${helpTextToRender}${
      helpTextToRender ? '.' : ''
    } Must be between ${minLength} and ${maxLength} characters`
  }

  if (minLength && !maxLength) {
    helpTextToRender = `${helpTextToRender}${helpTextToRender ? '.' : ''} Must be more than ${minLength} characters`
  }

  if (maxLength && !minLength) {
    helpTextToRender = `${helpTextToRender}${helpTextToRender ? '.' : ''} Must be less than ${maxLength} characters`
  }

  return (
    <div
      className={`${styles['input__container']} ${readOnly ? styles['input__container--read-only'] : ''} ${
        invalid ? styles['input__container--invalid'] : ''
      } ${styles[`input__container--${small ? 'small' : type}`]} ${containerClassName || ''}`}
    >
      <label className={styles['input__label']} htmlFor={id}>
        {labelText}
      </label>
      {!readOnly && type === 'checkbox' && (
        <Switch
          id={id}
          name={id}
          checked={value === 'true'}
          onCheckedChange={(checked) => onChange(checked.toString())}
          disabled={disabled}
        />
      )}
      {!readOnly && !nonStandardInputTypes.includes(type || '') && (
        <input
          type={type || 'text'}
          className={`${styles['input']} ${inputClassName || ''}`}
          id={id}
          name={id}
          value={value}
          onChange={(e) => onChange(type === 'checkbox' ? e.target.checked.toString() : e.target.value)}
          checked={type === 'checkbox' ? value === 'true' : undefined}
          style={inputStyle}
          disabled={disabled}
          inputMode={type === 'number' ? 'numeric' : undefined}
          placeholder={placeholder}
          maxLength={maxLength}
          minLength={minLength}
        />
      )}
      {!readOnly && type === 'textarea' && (
        <textarea
          className={`${styles['input']} ${inputClassName || ''}`}
          id={id}
          name={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={inputStyle}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          minLength={minLength}
        />
      )}
      {!readOnly && type === 'date' && (
        <DatePicker
          id={id}
          className={`${styles['input']} ${inputClassName || ''}`}
          dateFormat="dd/MM/yyyy"
          selected={value ? new Date(value) : undefined}
          onChange={(date) => onChange(date?.toISOString() || '')}
          disabled={disabled}
        />
      )}
      {!readOnly && type === 'currency' && (
        <CurrencyInput
          id={id}
          name={id}
          className={`${styles['input']} ${inputClassName || ''}`}
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          value={cleanValue({ value })}
          decimalsLimit={2}
          prefix='£'
          onValueChange={(_value, _name, values) => onChange((values?.formatted || 0) as unknown as string)}
        />
      )}
      {readOnly && (
        <p className={`${styles['input']} ${styles['input--readonly']} ${inputClassName || ''}`} style={inputStyle}>
          {type === 'currency' ? formatValue({ value, prefix: '£'}) : 
           type === 'checkbox' ? (value === 'true' ? 'Yes' : 'No') :
           readOnlyValue}
        </p>
      )}
      {!!helpTextToRender && <small className={styles['input__help-text']}>{helpTextToRender}</small>}
    </div>
  )
}

export default Input
