import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-tabs/style/react-tabs.css';
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoadingSpinner from './components/loading-spinner/LoadingSpinner';
import * as api from './api';
import axios from 'axios';
import { User } from './api/auth';
import LoginPage from './pages/LoginPage';
import { getBrand } from './utils/brand';
import '@silevis/reactgrid/styles.css';
import '@radix-ui/themes/styles.css';
import 'react-circular-progressbar/dist/styles.css';
import Plausible from 'plausible-tracker';
import { Theme } from '@radix-ui/themes';

export const plausible = Plausible({
  domain: 'app.operonix.com',
  trackLocalhost: false,
});

plausible.enableAutoPageviews();

export const trackEvent = (eventName: string, additionalData: object = {}) => {
  let environment = getBrand();
  if (window.location.hostname.includes('localhost')) {
    environment = 'local';
  }
  plausible.trackEvent(eventName, {
    props: {
      environment,
      ...additionalData,
    },
  });
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <LoadingSpinner className="loading__full-page" />
  </React.StrictMode>,
);

if (getBrand() === 'operonix') {
  document.title = 'Operonix';
  document.getElementById('favicon')?.setAttribute('href', '/operonix-favicon.ico');
}

const run = async () => {
  let isAuthed = false;

  const jwt = window.localStorage.getItem(api.auth.jwtLocalStorageKey);
  let user: User | undefined;
  if (jwt) {
    try {
      const superUserSelectedCustomer = window.localStorage.getItem('dodyl-super-customer-id');

      const headers: any = {
        'x-jwt-auth': jwt,
      };
      if (superUserSelectedCustomer) {
        headers['x-assume-customer'] = superUserSelectedCustomer;
      }
      const apiRes = await axios.get(`${api.baseURL}/user/me`, { headers });
      user = apiRes.data.user;
      isAuthed = !!user;
    } catch (error) {
      // @ts-ignore
      console.error(error.message);
    }
  }

  if (isAuthed && jwt && user) {
    root.render(
      // <React.StrictMode>
        <Theme>
          <App jwt={jwt} user={user} />
        </Theme>
      // </React.StrictMode>,
    );

    return;
  }

  root.render(
    // <React.StrictMode>
      <Theme>
        <LoginPage />
      </Theme>
    // </React.StrictMode>,
  );
};

run();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// window.localStorage.setItem(jwtLocalStorageKey, res.accessToken)
