interface Props {}

const DocumentIcon: React.FC<Props> = () => {
  return (
    <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9463 0H3.14286C1.40643 0 0 1.39222 0 3.11111V24.8889C0 26.6078 1.40643 28 3.14286 28H18.8571C20.5936 28 22 26.6078 22 24.8889V7.89069C19.2499 5.25 16.8928 2.33333 13.9463 0Z"
        fill="#E2E7FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 8H15.6C14.7168 8 14 7.2832 14 6.4V0"
        fill="#3E63DD"
      />
    </svg>
  );
};

export default DocumentIcon;
