import Input from '../../components/input/Input';
import './FilterCheckboxList.css';

interface Props {
  availableFilters?: string[];
  selectedFilters?: string[];
  setFilters: (selectedFilters: string[]) => void;
  labelText?: string;
}

const FilterCheckboxList: React.FC<Props> = ({
  availableFilters,
  selectedFilters,
  setFilters,
  labelText,
}) => {
  return (
    <div className="filter-checkbox-list">
      <p>{labelText}</p>
      <div className="filter-checkbox-list__values">
        {availableFilters?.map((process) => {
          const [id, name] = process.split('__');
          return (
            <div key={id} className='filter-checkbox-list-value'>
              <Input
                id={id}
                labelText={name || id}
                value={selectedFilters?.includes(id) ? 'true' : 'false'}
                onChange={(value) => {
                  if (value === 'true') {
                    setFilters([...(selectedFilters || []), id]);
                  } else {
                    setFilters(selectedFilters?.filter((filter) => filter !== id) || []);
                  }
                }}
                type="checkbox"
              />
            </div>
          );
        })}
        {!availableFilters?.length && <p className='filter-checkbox-list__no-values'>No filters available</p>}
      </div>
    </div>
  );
};

export default FilterCheckboxList;
