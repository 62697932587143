import { title } from 'case';
import { Case } from '../../../api/case';
import './CaseStatusChip.css';

interface Props {
  caseStatus: Case['status']
}

const CaseStatusChip: React.FC<Props> = ({ caseStatus }) => {

  let statusColor = 'yellow';
  if (caseStatus === 'ACTIVE') {
    statusColor = 'green';
  } else if (caseStatus === 'COMPLETED') {
    statusColor = 'gray-400';
  }
  return <p className={`case-status-chip case-status-chip--${statusColor}`} style={{ backgroundColor: `var(--${statusColor})` }}>{title(caseStatus)}</p>;
};

export default CaseStatusChip;
