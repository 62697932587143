import AsyncSelect from 'react-select/async';
import * as api from '../../../api';
import { CaseUser } from '../../../api/case';
import { Fragment, useContext, useState } from 'react';
import './CaseUserInput.css';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../App';

interface Props {
  caseId: string;
  ownerGroup: string;
  existingGroupCaseUsers: CaseUser[];
  onAddCaseUser: (caseUser: CaseUser) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleClose: () => void;
}

const NoOptionsMessage = ({ inputValue }: any) => {
  if (!inputValue) {
    return <p>Start typing to search by name or email</p>;
  }

  return (
    <p>
      Try using a less specific search term. <Link to="/admin/invite">Invite user</Link>
    </p>
  );
};

const CaseUserInput: React.FC<Props> = ({
  existingGroupCaseUsers,
  caseId,
  ownerGroup,
  onAddCaseUser,
  isOpen,
  setIsOpen,
  handleClose,
}) => {
  const ctx = useContext(AppContext);
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const existingGroupCaseUserIds = existingGroupCaseUsers.map((caseUser) => caseUser.user.id);

  const handleSearch = async (query: string) => {
    const res = await api.users.list(query);
    if (res.data) {
      return res.data.users
        .filter((user) => !existingGroupCaseUserIds.includes(user.id))
        .map((user) => {
          return {
            label: `${user.name} (${user.email})`,
            value: user.id,
          };
        });
    }

    return [];
  };

  const handleSubmit = async (userIdOverride?: string) => {
    const userId = selectedUserId || userIdOverride;
    if (!userId) {
      return;
    }

    const res = await api.cases.addCaseUser(caseId, userId, ownerGroup);
    if (res.data) {
      onAddCaseUser(res.data);
      setIsOpen(false);
      setSelectedUserId('');
      handleClose();
    }
  };

  return (
    <Fragment>
      {isOpen && (
        <div className="case-user-input">
          <AsyncSelect
            loadOptions={handleSearch}
            onChange={(val) => setSelectedUserId(val ? val.value : '')}
            placeholder="Search..."
            noOptionsMessage={NoOptionsMessage}
          />
          <div className="case-user-input__buttons">
            <button
              className="button button--secondary button--small"
              disabled={!selectedUserId}
              onClick={() => handleSubmit()}
            >
              Add User
            </button>
            <button className="link-button" onClick={handleClose}>
              Cancel
            </button>
            {ctx.user.roles.includes('admin') &&
              !existingGroupCaseUserIds.includes(ctx.user.id) && (
                <button className="link-button" onClick={() => handleSubmit(ctx.user.id)}>
                  Add yourself
                </button>
              )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CaseUserInput;
